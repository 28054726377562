import * as React from "react"
import Layout from "../components/layout"
import '../style.css'
import Star from "../components/star";
import InternalButton from "../components/internalButton";

const Education = () => {

    const activities=[
        {
            title:"Supernova Educator Guide",
            content:'The Fermi and XMM-Newton E/PO programs have developed a set of formal activities and background materials about supernovas',
            link:'/education/supernovaEG',
            image:'supernova.png'
        },
        {
            title:"TOPS Lessons Module",
            content:'TOPS Learning Systems has developed a three-booklet series of activities based on the math and science of the Fermi mission. In the TOPS tradition, each booklet has activities which use simple, inexpensive household materials (such as coins, paper plates, thread spools, etc.) in clever ways.',
            link:'/education/tops',
            image:'tops.png'
        },
        {
            title:"Active Galaxies Education Unit",
            content:'The Fermi E/PO team has developed a set of three activities to teach students about active galaxies. All three activities are aligned with the national science and mathematics standards.',
            link:'/education/activeGalaxyEU',
            image:'activeGalaxies.png'
        },
    ]

    return (
    <Layout>
        <div>
            <h1>EDUCATOR UNITS</h1>
            <Star />
            <div className="media-items-container">
                {activities.map( (a,index) =>
                    <div className="item-container" key={index}>
                        <a href={a.link}><img alt='placeholder'src={`/${a.image}`} className='image-styles'/></a>
                        <h4>{a.title}</h4>
                        <p>{a.content}</p>
                        <InternalButton link={a.link} />
                    </div>    
                )}
            </div>
        </div>   
    </Layout>
    )
}

export default Education